import React from "react"
import { WPApi } from "@api"
import {
    TextInput,
    AnimatedButton,
    AnimatedLink,
    AnimatedSection,
} from "@components/shared"

import halfmask from "./img/halfmask.svg"

import is from "is_js"

import {
    wrapp,
    news__wrapper,
    header,
    header__title,
    header__subtitle,
    form,
    form__input,
    form__button,
    form__msg,
    link__more,
} from "./styles/newslettermodal.module.scss"

class NewsletterForm extends React.Component {
    state = {
        email: "",
        msg: "",
        imBusy: false,
    }

    action = () => {
        if (is.email(this.state.email) === true) {
            localStorage.setItem("email", this.state.email)
            this.setState({
                imBusy: true,
            })
            WPApi.sendMail(this.state.email).then(({ message }) => {
                this.setState({
                    email: "",
                    msg: message,
                    imBusy: false,
                })
            })
        }
    }

    render() {
        if (this.state.imBusy) {
            return (
                <div className={form}>
                    <h6 className={form__msg}>Dodaję Cię do listy mailingowej</h6>
                </div>
            )
        }
        if (this.state.msg !== "") {
            return (
                <div className={form}>
                    <h6 className={form__msg}>{this.state.msg}</h6>
                </div>
            )
        }

        const {
            title,
            subtitle,
        } = this.props

        return (
            <div className={news__wrapper}>
                <div className={header}>
                    <h3 className={header__title}>{title}</h3>
                    <h5 className={header__subtitle}>{subtitle}</h5>

                </div>
                <AnimatedSection className={form}>
                    <TextInput
                        type="email"
                        className={form__input}
                        value={this.state.email}
                        placeholder="Wprowadź adres email"
                        onChange={(ev) => {
                            const { value } = ev.target
                            this.setState({
                                email: value,
                            })
                        }}
                    />
                    <AnimatedButton className={form__button} onClick={this.action}>
                        ZAPISZ SIĘ
              <i className="icofont-double-right"></i>
                    </AnimatedButton>

                    <AnimatedLink to={"/polityka-prywatnosci/"} className={link__more}>
                        <small>
                            * Zapisując się do newslettera zgadzasz się na przetwarzanie
                            swoich informacji osobowych zgodnie z naszą Polityką Prywatności.
              </small>
                    </AnimatedLink>
                </AnimatedSection>

            </div>
        )
    }
}

export const NewsletterInModal = ({ title, subtitle }) => {
    return (
        <div className={wrapp} id={"newsletter"}>
            <img src={halfmask} alt="" />
            <NewsletterForm title={title} subtitle={subtitle} />
        </div>
    )
}

export default NewsletterInModal