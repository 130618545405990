import React from 'react'
import PropTypes from 'prop-types'
import { getSortedSrcSet } from './logic'

class ScalableImage extends React.Component {
	state = {
		sortedSrcSet: [],
    sourceUrl: '',
    afterResize:false
	}

	static defaultProps = {
		altText: 'Image on page',
	}

	static getDerivedStateFromProps(props, current_state) {
		if (current_state.afterResize === false) {
			const { srcSet } = props
			const sortedUrlSources = getSortedSrcSet(srcSet)
      const [selectedImage] = sortedUrlSources
			return {
				sourceUrl: selectedImage[0],
				sizes: selectedImage[2],
				sortedSrcSet: sortedUrlSources,
			}
		}

		return current_state
	}

	componentDidMount() {
		const { srcSet } = this.props
		const sortedUrlSources = getSortedSrcSet(srcSet)
		let [selectedImage] = sortedUrlSources.filter(
			(el) => Number(el[1].replace('w', '')) >= window.innerWidth
    )
		if (!selectedImage) {
			selectedImage = sortedUrlSources[sortedUrlSources.length - 1]
		}  
    
    this.setState({
      sourceUrl: selectedImage[0],
      sortedSrcSet: sortedUrlSources,
      afterResize: true
    })
	}

	render() {
		const { altText, style = {}, className } = this.props
		const { sourceUrl } = this.state
		return (
			<img
				loading='lazy'
				alt={altText}
				src={sourceUrl}
				style={style}
				className={className}
			/>
		)
	}
}

ScalableImage.propTypes = {
	altText: PropTypes.string,
	srcSet: PropTypes.string.isRequired,
	sourceUrl: PropTypes.string.isRequired,
}

export default ScalableImage
