import React from "react"
import { Layout } from "@components/layout"
import { CategoryItem } from "@components/ecommerce"
import { noitems, shop__category__wrapper } from "./styles/checkout.module.scss"

const renderWithAllProps = (Comp) => (entry, key) => {
  return <Comp {...entry} key={key} />
}

const NoItemsInCart = (props) => {
  const { pageContext, cart } = props
  const { page, categories } = pageContext
  const { imBusy } = cart
  // const shuffledCategories = categories.sort(() => Math.random() - 0.5)

  return (
    <Layout {...page} imBusy={imBusy}>
      <div className={noitems}>
        <h1>Twój koszyk jest pusty.</h1>
      <div className={shop__category__wrapper}>
        {categories.map(renderWithAllProps(CategoryItem))}
      </div>
      </div>
    </Layout>
  )
}

export default NoItemsInCart
