import React, { useState } from "react"
import { TextInput } from "@components/shared"
import { FlexRow } from "@components/layout"
import { createReview, retrieveReviews } from "./logic"
import helmetimg from "./logo vector.svg"

import {
  reviews__wrapper,
  reviews__list,
  reviews__list__item,
  reviews__list__item__author,
  reviews__list__item__date,
  reviews__list__item__stars,
  reviews__list__item__star__active,
  reviews__list__item__content,
  reviews__form,
  reviews__form__item,
  reviews__form__rating,
  reviews__form__rating__active,
  reviews__form__button,
  reviews__form__message,
  helmet,
} from "./reviews.module.scss"
const ContextReview = ({ dateGmt, content, author }) => {
  return (
    <div>
      <h5>{dateGmt}</h5>
      <h5>{author.email}</h5>
      <h5 dangerouslySetInnerHTML={{ __html: content }} />
    </div>
  )
}

const SingleReview = ({ date_created, reviewer, review, rating }) => {
  return (
    <div className={reviews__list__item}>
      <FlexRow justify={"space-between"}>
        <div className={reviews__list__item__stars}>
          <i
            className={`icofont-star ${
              rating > 0 && reviews__list__item__star__active
            }`}
          />
          <i
            className={`icofont-star ${
              rating > 1 && reviews__list__item__star__active
            }`}
          />
          <i
            className={`icofont-star ${
              rating > 2 && reviews__list__item__star__active
            }`}
          />
          <i
            className={`icofont-star ${
              rating > 3 && reviews__list__item__star__active
            }`}
          />
          <i
            className={`icofont-star ${
              rating > 4 && reviews__list__item__star__active
            }`}
          />
        </div>
        <h5 className={reviews__list__item__date}>
          {date_created.substr(0, 10)}
        </h5>
      </FlexRow>
      <h5 className={reviews__list__item__author}>{reviewer}</h5>
      <h5
        className={reviews__list__item__content}
        dangerouslySetInnerHTML={{ __html: review }}
      />
    </div>
  )
}

const ReviewForm = ({ onSubmit, msg }) => {
  const [state, setState] = useState({
    review: "",
    rating: 5,
    reviewer: "",
    reviewer_email: "",
  })

  const onInputChange = (name) => (ev) => {
    setState({
      ...state,
      [name]: ev.target.value,
    })
  }

  return (
    <form
      className={reviews__form}
      onSubmit={(ev) => {
        ev.preventDefault()
        onSubmit(state)
        setState({
          review: "",
          rating: 5,
          reviewer: "",
          reviewer_email: "",
        })
      }}
    >
      <div className={reviews__form__item}>
        <textarea
          value={state.review}
          placeholder="Napisz co sądzisz o tym produkcie"
          onChange={onInputChange("review")}
        />
      </div>
      <div className={reviews__form__rating}>
        <i
          className={`icofont-star ${
            state.rating > 0 && reviews__form__rating__active
          }`}
          onClick={() => {
            onInputChange("rating")({ target: { value: 1 } })
          }}
        />
        <i
          className={`icofont-star ${
            state.rating > 1 && reviews__form__rating__active
          }`}
          onClick={() => {
            onInputChange("rating")({ target: { value: 2 } })
          }}
        />
        <i
          className={`icofont-star ${
            state.rating > 2 && reviews__form__rating__active
          }`}
          onClick={() => {
            onInputChange("rating")({ target: { value: 3 } })
          }}
        />
        <i
          className={`icofont-star ${
            state.rating > 3 && reviews__form__rating__active
          }`}
          onClick={() => {
            onInputChange("rating")({ target: { value: 4 } })
          }}
        />
        <i
          className={`icofont-star ${
            state.rating > 4 && reviews__form__rating__active
          }`}
          onClick={() => {
            onInputChange("rating")({ target: { value: 5 } })
          }}
        />
      </div>
      <TextInput
        className={reviews__form__item}
        value={state.reviewer}
        placeholder="Twoje imie i nazwisko"
        onChange={onInputChange("reviewer")}
        validate="text"
      />
      <TextInput
        className={reviews__form__item}
        value={state.reviewer_email}
        placeholder="Twój adres email"
        onChange={onInputChange("reviewer_email")}
        type="email"
        validate="email"
      />

      {msg ? (
        <small className={reviews__form__message}>{msg}</small>
      ) : (
        <button
          className={reviews__form__button}
          disabled={
            state.reviewer_email == "" || state.reviewer == "" ? true : false
          }
        >
          Prześlij opinię
        </button>
      )}
    </form>
  )
}

class Reviews extends React.Component {
  constructor(props) {
    super(props)
    this.addNewReview = this.addNewReview.bind(this)
  }

  state = {
    reviews: [],
    imBusy: true,
    msg: "",
  }

  componentDidMount() {
    retrieveReviews(this.props.productId)
      // retrieveReviews()
      .then((data) => {
        this.setState({
          reviews: data,
          imBusy: false,
        })
      })
      .catch(() => {
        this.setState({
          imBusy: false,
        })
      })
  }

  addNewReview(form) {
    const { productId } = this.props
    this.setState({ imBusy: true, msg: "" })
    return createReview(productId, form)
      .then((data) => {
        this.setState({
          reviews: [data, ...this.state.reviews],
          imBusy: false,
          msg: "Dziękujemy za Twoją opinię",
        })
      })
      .catch((err) => {
        this.setState({
          imBusy: false,
          msg: "Wystąpił błąd, spróbuj później",
        })
      })
  }

  render() {
    const { imBusy, reviews, msg } = this.state
    const { contextReviews } = this.props

    return (
      <div className={reviews__wrapper}>
        <img className={helmet} src={helmetimg} alt="" />
        <div>
          {!reviews && !imBusy
            ? contextReviews.map((el, k) => <ContextReview {...el} />)
            : null}
        </div>
        {!imBusy ? (
          <div className={reviews__list}>
            {reviews.map((el, k) => (
              <SingleReview {...el} key={el.id} />
            ))}
          </div>
        ) : (
          <small>Ładuję opinie</small>
        )}
        {!imBusy && reviews.length === 0 && null}
        <ReviewForm onSubmit={this.addNewReview} msg={msg} />
      </div>
    )
  }
}

export default Reviews
