import React from "react"
import { TextInput } from "@components/shared"
import { FlexCol } from "@components/layout"
import GitHubLogin from "react-github-login"
import { GitHubApi } from "@api"
import { CustomerCtx, useActions, useContextState } from "@components/contexted"

import {
  customer__form,
  gitlogin,
  git__wrap,
} from "../styles/checkout.module.scss"

const onSuccessGithub = (changeValue) => (data) => {
  GitHubApi.exchangeCode(data.code).then((data) => {
    if (data) {
      changeValue({
        note: data.login,
      })
    }
  })
}

const CustomerForm = () => {
  const onFailGithub = (data) => {
    console.log("onFailGithub", data)
  }

  const { changeValue } = useActions(CustomerCtx, ["changeValue"])
  const {
    first_name,
    last_name,
    email,
    note,
    address_1,
    city,
    postcode,
    country,
  } = useContextState(CustomerCtx, [
    "first_name",
    "last_name",
    "email",
    "note",
    "address_1",
    "city",
    "postcode",
    "country",
  ])
  return (
    
    <form
      className={customer__form}
      onSubmit={(ev) => {
        ev.preventDefault()
      }}
    >
      <FlexCol justify={"flex-start"}>
        <h5>Dane osobowe</h5>
        <TextInput
          placeholder="Imię"
          name="firstName"
          value={first_name}
          validate={"text"}
          onChange={(ev) => {
            changeValue({
              first_name: ev.target.value,
            })
          }}
        />
        <TextInput
          placeholder="Nazwisko"
          name="lastName"
          value={last_name}
          onChange={(ev) => {
            changeValue({
              last_name: ev.target.value,
            })
          }}
        />
        <TextInput
          type={"email"}
          name="email"
          validate={"email"}
          placeholder="Adres email"
          value={email}
          onChange={(ev) => {
            changeValue({
              email: ev.target.value,
            })
          }}
        />
        {note === "" ? (
          <div className={git__wrap}>
            <h5>
              Zaloguj się do GitHuba, aby potwierdzić że jesteś programistą
            </h5>
            <GitHubLogin
              clientId="423ce2815e11fd57096f"
              onSuccess={onSuccessGithub(changeValue)}
              onFailure={onFailGithub}
              buttonText={<i class="fab fa-github"></i>}
              className={gitlogin}
              valid={true}
              redirectUri="https://gladiators-of-javascript.com"
            />
          </div>
        ) : (
          <TextInput
            disabled={true}
            placeholder="Nick na GitHubie"
            name="customerNote"
            value={note}
            onChange={(ev) => {
              changeValue({
                note: ev.target.value,
              })
            }}
          />
        )}
        {/* <div className={git__wrap}>
          <TextInput
            placeholder="Nick na GitHubie"
            name="customerNote"
            value={note}
            onChange={(ev) => {
              changeValue({
                note: ev.target.value,
              })
            }}
          />

          <GitHubLogin
            clientId="423ce2815e11fd57096f"
            onSuccess={onSuccessGithub(changeValue)}
            onFailure={onFailGithub}
            buttonText={<i class="fab fa-github"></i>}
            className={gitlogin}
            valid={true}
            redirectUri="https://gladiators-of-javascript.com"
          />
        </div> */}
        <h5>Dane do wysyłki</h5>
        <TextInput
          placeholder="Ulica"
          name="address"
          value={address_1}
          onChange={(ev) => {
            changeValue({
              address_1: ev.target.value,
            })
          }}
        />
        <TextInput
          placeholder="Miasto"
          name="city"
          value={city}
          onChange={(ev) => {
            changeValue({
              city: ev.target.value,
            })
          }}
        />
        <TextInput
          placeholder="Kod pocztowy"
          value={postcode}
          name="zipCode"
          onChange={(ev) => {
            changeValue({
              postcode: ev.target.value,
            })
          }}
        />
        <TextInput
          placeholder="Kraj"
          name="country"
          value={country}
          onChange={(ev) => {
            changeValue({
              country: ev.target.value,
            })
          }}
        />
      </FlexCol>
    </form>
  )
}

export default CustomerForm
