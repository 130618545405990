import { WPApi, PayUApi } from "@api"

const getAllProducts = () => {
  return WPApi.getAllProducts().then((response) => {
    const { data } = response
    return data
  })
}

const createOrder = (props) => {
  const { cart, initLoading, customer } = props
  let { shipping, items, coupon } = cart
  if (
    customer.first_name.length === 0 ||
    customer.last_name.length === 0 ||
    customer.email.length === 0 ||
    customer.note.length === 0  ||
    customer.address_1.length === 0 ||
    customer.city.length === 0 ||
    customer.postcode === 0 ||
    customer.country === 0

  ) {
    return Promise.reject({
      data: { message: "Niepoprawnie wypełniony formularz z danymi" },
    })
  }

  if (typeof shipping.total !== "number") {
    return Promise.reject({
      data: { message: "Brak wybranego typu wysyłki" },
    })
  }

  if (items.length === 0) {
    return Promise.reject({
      data: { message: "Brak produktów w koszyku" },
    })
  }

  initLoading()
  // fixes for formats
  shipping = { ...shipping, total: String(shipping.total) }
  if (coupon.length !== 0) {
    coupon = { coupon_lines: [{ code: coupon }] }
  }

  const _cart = items.map((el) => {
    const { product_id, variant_id, quantity } = el
    return variant_id
      ? {
          product_id,
          quantity,
          variation_id: variant_id,
        }
      : {
          product_id,
          quantity,
        }
  })

  console.log("tutaj")
  return WPApi.postCreateOrder(customer, _cart, shipping, coupon)
}

const initPayment = (props) => {
  const { cart, initLoading } = props
  initLoading()
  return PayUApi.initPayment(cart.orderInApi.id)
}

export { getAllProducts, createOrder, initPayment }
