import React, { Component, useContext, useRef } from "react"
import { AnimatedLink, AnimatedButton, Modal } from "@components/shared"
import { QuantityChanger } from "@components/shared"
import { CartCtx } from "@components/contexted"
import { NewsletterSection } from "@components/layout"

import {
  product__price,
  product__btns,
  product__btn,
  product__btnAlt,
  product__addtocart,
  product__prices,
  product__modal,
} from "../styles/product.module.scss"

const cleanPrice = (price) => {
  return parseFloat(price.replace(/[$,]+/g, ""))
}

const Prices = ({ sale, regularValue, value, unit }) => {
  return (
    <div className={product__price}>
      {sale && (
        <span>
          {regularValue}
          {unit}
        </span>
      )}
      {sale && (
        <small>
          {value}
          {unit}
        </small>
      )}
      {!sale && (
        <small>
          {value}
          {unit}
        </small>
      )}
    </div>
  )
}

// Tak wygląda używanie dispatcha
// budujemy małe komponenty i w nich używamy context
const AddToCartButton = ({ product, onInit }) => {
  const { dispatch } = useContext(CartCtx)
  // const modal = useRef()
  // let email = null
  // let closed = null
  // if (typeof window !== 'undefined') {
  //   email = localStorage.getItem("email")
  //   closed = localStorage.getItem("modal_closed")
  // }

  return (
    <React.Fragment>
      {/* <Modal
        className={product__modal}
        ref={modal}
        title=""
        onClose={() => localStorage.setItem("modal_closed", "true")}>
        <NewsletterSection
          modal={true}
          title="Aktualności i kody rabatowe w twojej skrzynce"
          subtitle="Zapisz się aby otrzymywać kody rabatowe oraz informacje o promocjach" />
      </Modal> */}
      <AnimatedButton
        className={product__btn}
        onClick={() => {
          console.log("dodaje produkt", product)
          // if (email === null && closed !== "true") {
          //   modal.current.toggle()
          // }
          dispatch({
            type: "addToCart",
            payload: product,
          })
          dispatch({
            type:"changeProductQty",
            payload: {
              product_id: product.product_id,
              quantity: product.quantity,
            }
          })
          const ifFacebookPixelLoaded = fbq
          if(ifFacebookPixelLoaded){
            fbq('track', "addToCart")
          }
          onInit()
        }}
      >
        <i className="icofont-plus"></i>
        <i class="fas fa-shopping-cart"></i>
      </AnimatedButton>
    </React.Fragment>

  )
}
// end

class AddToCart extends Component {
  state = {
    value: null,
    regularValue: null,
    quantity: 1,
    product_id: null,
    variation_id: null,
    variant: null,
    sale: false,
    price: "",
    regularPrice: "",
    unit: "PLN",
    name: "",
    slug: "",
    image: null,
  }

  changeQty = (val) => {
    const _number = Number(val)
    if (_number <= 0 || Number.isNaN(_number)) {
      return false
    } else {
      this.setState({
        quantity: _number,
        value: _number * this.state.price,
        regularValue: _number * this.state.regularPrice,
      })
    }
  }

  onInit = () => {
    if (this.props.salePrice) {
      this.setState({
        product_id: this.props.productId,
        // variation_id:this.props.variationId,
        name: this.props.name,
        slug: this.props.slug,
        sale: true,
        price: cleanPrice(this.props.salePrice),
        regularPrice: cleanPrice(this.props.regularPrice),
        regularValue: cleanPrice(this.props.regularPrice),
        value: cleanPrice(this.props.salePrice),
        quantity: 1,
        image: this.props.image,
      })
    } else {
      this.setState({
        product_id: this.props.productId,
        // variation_id:this.props.variationId,
        name: this.props.name,
        slug: this.props.slug,
        price: cleanPrice(this.props.regularPrice),
        regularPrice: cleanPrice(this.props.regularPrice),
        regularValue: cleanPrice(this.props.regularPrice),
        value: cleanPrice(this.props.regularPrice),
        quantity: 1,
        image: this.props.image,
      })
    }
  }

  componentDidMount() {
    this.onInit()
  }

  render() {
    const { quantity, value, regularValue, sale, unit } = this.state
    return (
      <div className={product__addtocart}>
        <div className={product__prices}>
          <QuantityChanger onChange={this.changeQty} quantity={quantity} />
          <Prices {...{ value, regularValue, sale, unit }} />
        </div>

        <div className={product__btns}>
          {/* użycie */}
          <AddToCartButton product={this.state} onInit={this.onInit} />
          <AnimatedLink
            className={`${product__btn} ${product__btnAlt}`}
            to="/shop/checkout/"
          >
            <i class="fas fa-credit-card"></i>
            <i class="fas fa-angle-double-right"></i>
          </AnimatedLink>
        </div>
      </div>
    )
  }
}

export default AddToCart

