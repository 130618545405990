import React from "react"
import { AnimatedLink } from "@components/shared"
import {
  wrapper,
} from "./styles/double.module.scss"

import {
  arrows,
  arrows__left,
  arrows__right,
} from "../../theme/DirectionArrows/arrows.module.scss"

export const DoubleCTASection = ({ label1, link1, label2, link2 }) => {
  return (
    <div className={wrapper}>
      <div className={arrows}>
        <AnimatedLink className={arrows__left} to="/gladiators">
          <a href={link1}>{label1}</a>
        </AnimatedLink>

        <AnimatedLink className={arrows__right} to="/tasks">
          <a href={link2}>{label2}</a>
        </AnimatedLink>
      </div>
    </div>
  )
}

export default DoubleCTASection
