import React from 'react'
import './zoom.scss'

class Zoom extends React.Component {
  state = {
    backgroundImage: `url(${this.props.sourceUrl})`,
    backgroundPosition: '0% 0%'
  }

  handleMouseMove = e => {
    const { left, top, width, height } = e.target.getBoundingClientRect()
    const x = (e.pageX - left) / width * 100
    const y = (e.pageY - top) / height * 100
    this.setState({ backgroundPosition: `${x}% ${y}%` })
  }

  render(){
      const {
        sourceUrl
      } = this.props

    return(
    <figure onMouseMove={this.handleMouseMove} style={this.state}>
        <img src={sourceUrl} alt=""/>
    </figure>
    )
  }
}

export default Zoom