import { WPApi, PayUApi } from "@api"

const processingPaymentAndOrder = ({
  location,
  changePaymentStatus = () => {},
  setOrderFromApi = () => {},
  resetCart = () => {},
}) => {
  let error = new URLSearchParams(location.search).get("error")
  if (error) {
    changePaymentStatus({ msg: error, phase: "error" })
    return Promise.resolve(true)
  }

  changePaymentStatus({
    msg: "Przetwarzanie płatności.Proszę czekać",
    phase: "error",
  })

  let paymentId = new URLSearchParams(location.search).get("id")

  
  if (Number.isNaN(paymentId)) {
    changePaymentStatus({ msg: "paymentId isNaN", phase: "error" })
    return Promise.resolve(true)
  }

  return WPApi.searchByPaymentId(paymentId)
    .then(([order, ...rest]) => {
      if (!order) {
        console.error("dont have order with paymentId")
        changePaymentStatus({
          msg: "dont have order with paymentId",
          phase: "error",
        })
        return Promise.resolve(true)
      }

      const { status, meta_data } = order
      if (status !== "processing") {
        console.error(
          "payment probably completed or canceled by user",
          status,
          order
        )
        changePaymentStatus({
          msg: "Płatność zakończona lub przerwana przez użytkownika.",
          phase: "info",
        })

        return Promise.resolve(true)
      }

      return PayUApi.getTransactionStatus(meta_data[0].value)
        .then((transaction) => {  
          const exaclyOneOrderInTransaction =
            Array.isArray(transaction.orders) && transaction.orders.length === 1
          if (!exaclyOneOrderInTransaction) {
            // console.error("not exacly One Order In Transaction", transaction)
            changePaymentStatus({
              msg: "not exacly One Order In Transaction - payment gates stuff",
              phase: "error",
            })

            return Promise.resolve(true)
          }
          const [payment] = transaction.orders
          const { status } = payment

          console.log("status platnosci", status)

          if (status !== "COMPLETED") {
            if(typeof window !== "undefined"){
              setTimeout(() => {
                window.location.reload(true);
                changePaymentStatus({
                  msg: "Płatność nie została ukończona w serwisie płatniczym.",
                  phase: "info",
                })
              }, 7000)
            }
            return Promise.resolve(true)

              // if (status !== "COMPLETED"){
              //     console.error(
              //     "payment status is not completed",
              //     status,
              //     transaction
              //   )
              //   changePaymentStatus({
              //     msg: "Płatność nie została ukończona w serwisie płatniczym.",
              //     phase: "info",
              //   })
              // return Promise.resolve(true)
              // }          
             ///////// 
            
            // console.error(
            //   "payment status is not completed",
            //   status,
            //   transaction
            // )
            // changePaymentStatus({
            //   msg: "Płatność nie została ukończona w serwisie płatniczym.",
            //   phase: "info",
            // })

          }

          return WPApi.changeOrder(paymentId)
            .then((changedOrder) => {
              console.log('status === "COMPLETED"', changedOrder)
              changePaymentStatus({
                msg:
                  "Dziękujemy za zakupy, wszystkie informacje powinieneś dostać na wskazanego maila",
                phase: "success",
              })
              setOrderFromApi(changedOrder)
              resetCart()

              return true
            })
            .catch((error) => {
              console.error("cannot change order status", error)
              changePaymentStatus({
                msg: "cannot change order status",
                phase: "error",
              })

              return error
            })
        })
        .catch((error) => {
          console.error("wrong transaction id", error)
          changePaymentStatus({ msg: "wrong transaction id", phase: "error" })

          return error
        })
    })
    .catch((error) => {

      console.error("api logic error", error)
      changePaymentStatus({ msg: "api logic error", phase: "error" })

      // 404 / 500
      return error
    })
}
export { processingPaymentAndOrder }
