import React, { useContext } from "react"
import { ProductSummaryItem, AnimatedLink as A } from "@components/shared"
import { CartCtx, useContextState } from "@components/contexted"

import coffe from "./img/coffee-cup-hot.png"

import {
  summary__wrapper,
  summary__content,
  summary__content__coffe,
  summary__content__coffe__buttons,
  summary__content__coffe__buttons__btn,
} from "../styles/checkout.module.scss"

const cleanPrice = (price) => {
  return parseFloat(price.replace(/[$,]+/g, ""))
}

const ProductsSummary = ({
  changeProductQty,
  removeFromCart,
  pageContext,
  presentation = false,
}) => {
  const { productsWithDetails } = pageContext
  const coffeProduct = productsWithDetails.find((el) => el.productId === 374)
  const { dispatch } = useContext(CartCtx)
  const { items } = useContextState(CartCtx, ["items"])

  return (
    <React.Fragment>
      <div className={summary__wrapper}>
        {items.map((el, k) => (
          <ProductSummaryItem
            {...el}
            key={k}
            changeProductQty={changeProductQty}
            removeFromCart={removeFromCart}
          />
        ))}
      </div>
      <div className={summary__content}>
        <div className={summary__content__coffe}>
          <h5>Postaw kawę twórcy bloga</h5>
          <div className={summary__content__coffe__buttons}>
            <A
              to={"/shop/checkout"}
              onClick={() => {
                dispatch({
                  type: "addToCart",
                  payload: {
                    value: cleanPrice(coffeProduct.regularPrice),
                    regularValue: cleanPrice(coffeProduct.regularPrice),
                    quantity: 1,
                    product_id: coffeProduct.productId,
                    variation_id: null,
                    variant: null,
                    sale: false,
                    price: cleanPrice(coffeProduct.regularPrice),
                    regularPrice: cleanPrice(coffeProduct.regularPrice),
                    unit: "PLN",
                    name: coffeProduct.name,
                    slug: coffeProduct.slug,
                    image: coffeProduct.image,
                  },
                })
              }}
            >
              <a className={summary__content__coffe__buttons__btn}>
                <img src={coffe}></img>
              </a>
            </A>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default ProductsSummary
