import React from "react"
import { AnimatedSection, AnimatedLink } from "@components/shared"
import logoY from "../../../images/logo-yellow.png"
import {
  app__footer__section,
  app__footer,
  app__footer__logo,

  app__footer__icons,
  app__footer__media__icon,
  app__footer__copyFoot,
} from "./footer.module.scss"

// const withDelay = (fn, delay = 2000) => {
//   const timer = setTimeout(() => {
//     fn()
//     clearTimeout(timer)
//   }, delay)
// }

export const Footer = ({ className }) => {
  return (
    <div className={app__footer__section}>
      <div className={app__footer}>
        <AnimatedSection>
          <div className={app__footer__logo}>
            {/* <figure> */}
            <img src={logoY} alt="logo" />
            <figcaption>GLADIATORZY</figcaption>
            <figcaption>JAVASCRIPTU</figcaption>
            {/* </figure> */}
          </div>
        </AnimatedSection>
        {/* <ul className={app__footer__list}>
          <li className={app__footer__list__item}>
            <a href="/tasks/">Katalog kursów</a>
          </li>
          <li className={app__footer__list__item}>
            <a href="/about/">O nas</a>
          </li>
          <li className={app__footer__list__item}>
            <a href="/contact/">Kontakt</a>
          </li>
        </ul> */}
        <div className={app__footer__icons}>
          <AnimatedLink
            className={app__footer__media__icon}
            to="https://www.facebook.com/localhost40310/"
          >
            <i className="fab fa-facebook-square"></i>
          </AnimatedLink>
          <AnimatedLink
            className={app__footer__media__icon}
            to="https://github.com/Przemocny/zbior-zadan-html-css-js-react"
          >
            <i className="fab fa-github"></i>
          </AnimatedLink>
        </div>
      </div>
      <div className={app__footer__copyFoot}>
        &copy; Copyright 2020 by Localhost Group sp.z.o.o{" "}
      </div>
    </div>
  )
}

export default Footer
