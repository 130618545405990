import React from "react"
import { CartCtx, useActions } from "@components/contexted"

import { ProductsSummary, DiscountAndContinue, ErrorMessage } from ".."

const CartSummaryStep = (props) => {
  const { cart, removeFromCart, setCoupon, stepper, pageContext } = props
  const { changeProductQty } = useActions(CartCtx, ["changeProductQty"])

  return (
    <div title={"1. Podsumowanie"}>
      <ProductsSummary
        cart={cart}
        changeProductQty={changeProductQty}
        removeFromCart={removeFromCart}
        pageContext={pageContext}
      />
      <ErrorMessage cart={cart} />
      <DiscountAndContinue
        sum={cart.sum}
        shipping={cart.shipping}
        nextStep={() => {
          stepper.current.setIndex(1, true)
        }}
        coupon={cart.coupon}
        onCouponChange={setCoupon}
      />
    </div>
  )
}

export default CartSummaryStep
