import React, { useRef, useContext } from "react"
import { useSpring, animated } from "react-spring/"
import { useStaticQuery, graphql } from "gatsby"
import { WindowCtx } from "../../../reduxWrapper"
import { onScroll, getProperImgSize } from "./logic"

import {
  parallax,
  parallax__img,
  parallax__inner,
  parallax__inner__master__title,
  parallax__inner__master__subtitle
} from "./parallax.module.scss"

const ParallaxInner = ({ children, value, image, height, masterTitle, masterSubtitle, bordered }) => {
  const src = getProperImgSize(image, value)
  const ref = useRef(null)

  const { y } = useSpring({
    config: { mass: 3, tension: 140, friction: 34 },
    y: onScroll(value, ref),
  })

  const heightObj = height
    ? {
        height: height,
        minHeight: height,
      }
    : {}

  const style = {
    transform: y.interpolate((_y) => `translate(-50%, ${_y}%)`),
    opacity: 0.5,
    transformStyle: "preserve-3d",
    backfaceVisibility: "hidden",
    filter: "blur(3px)",
    objectFit: "cover",
  }

  return (
    <div ref={ref} className={parallax} style={heightObj}>
      <animated.img src={src} style={style} className={parallax__img} />
      <div className={parallax__inner}>
        <h2 className={parallax__inner__master__title}>{masterTitle}</h2>
        <h4 className={parallax__inner__master__subtitle}>{masterSubtitle}</h4>
        {children}</div>
    </div>
  )
}

const ParallaxMaster = React.memo(({ image, children, height, masterTitle, masterSubtitle }) => {
  const { WP } = useStaticQuery(graphql`
    query {
      WP {
        mediaItemBy(slug: "parallax_img") {
          sourceUrl
          srcSet
          altText
        }
      }
    }
  `)

  const value = useContext(WindowCtx)

  return (
    <ParallaxInner
      image={image || WP.mediaItemBy}
      value={value}
      height={height}
      masterTitle={masterTitle}
      masterSubtitle={masterSubtitle}
    >
      {children}
    </ParallaxInner>
  )
})

export default ParallaxMaster
