import React, { useContext, useEffect } from "react"
import failed from "./img/DENIED.png"
import { WPApi } from "@api"
import {
  CustomerCtx,
  CartCtx,
  AppCtx,
  useActions,
  useContextState,
} from "@components/contexted"
import { AnimatedButton } from "@components/shared"

import { initPayment, createOrder } from "../Checkout/common"
import {
  summary__stats,
  summary__stats__button,
  summary__stats__links,
  summary__stats__links__single,
} from "./styles/summary.module.scss"

const PurchaseFailed = ({ orderId, msg }) => {
  const customer = useContext(CustomerCtx)
  const cart = useContext(CartCtx)

  const { loading, loaded } = useActions(AppCtx, ["loading", "loaded"])
  const { setOrderFromApi, onOrderApiError } = useActions(CartCtx, [
    "setOrderFromApi",
    "onOrderApiError",
  ])
  const { imBusy } = useContextState(AppCtx, "imBusy")

  // cancel failed
  const cancelOrder = (orderId) => {
    return WPApi.cancelOrder(orderId)
  }

  // create new order from cart
  const createNewOrder = (cart) => {
    return createOrder({
      cart: cart.state,
      customer: customer.state,
      initLoading: loading,
    })
      .then((data) => {
        loading()
        setOrderFromApi(data)
        return true
      })
      .catch(({ data }) => {
        loading()
        onOrderApiError(data)
        return false
      })
  }
  const payNewOrder = (cart) => {
    return initPayment({
      cart,
      initLoading: loading,
    }).then((payment) => {
      loaded()
      window.location.href = payment.redirectUri
    }).catch(({ data }) => {
      onOrderApiError(data)
    })
  }

  // jeśli wleci nowe zamówienie po kliknieciu, przenieś do płatności
  useEffect(() => {
    if ('id' in cart.state.orderInApi) {
      payNewOrder(cart.state)
    }
  }, [cart.state.orderInApi])


  return (
    <div className={summary__stats}>
      <h3>
        Status: <strong>Proszę czekać, trwa procesowanie zamówienia.</strong>
      </h3>
      <div className="row justify-content-center">
        {imBusy ? (
          <h3><strong>Proszę czekać, trwa procesowanie zamówienia.</strong></h3>
        ) : (
            <AnimatedButton className={summary__stats__button} onClick={() => {
              cancelOrder(orderId); createNewOrder(cart)
            }} disabled={imBusy}>
              Zapłać ponownie
              <i className="icofont-refresh"></i>
            </AnimatedButton>
          )}
      </div>

      <h5>W przypadku problemu ze zrealizowaniem zakupów prosimy o kontakt:</h5>
      <div className={summary__stats__links}>
        <div className={summary__stats__links__single}>
          <i class="fab fa-facebook-square"></i>
          <h5>
            <a target="_blank" href="https://www.facebook.com/localhost40310">
              {" "}
              Localhost Group
            </a>
          </h5>
        </div>
        <div className={summary__stats__links__single}>
          <i class="far fa-envelope"></i>
          <h5>
            <a href="mailto:contact@localhost-group.com">
              {" "}
              contact@localhost-group.com
            </a>
          </h5>
        </div>
        <div className={summary__stats__links__single}>
          <i class="fas fa-mobile-alt"></i>
          <h5>
            <a href="tel:+48535000959">(+48) 535 000 959</a>
          </h5>
        </div>
      </div>
      <img src={failed} alt="payment-failed" />
    </div>
  )
  // return ['tresc błędu', 'nr zamówienia', 'link do platnosci', 'contact ze sklepem'].join(' | ')
}
export default PurchaseFailed
