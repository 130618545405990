import React, { useState } from "react"
import { CartCtx, useActions, useContextState } from "@components/contexted"
import { cart__summary, discount__wrapper } from "../cart.module.scss"
import { WPApi } from "@api"

const Summary = ({ sum, unit }) => {
  const [state, setState] = useState({ coupons: [] })
  const { setCoupon } = useActions(CartCtx, ["setCoupon"])
  const { coupon } = useContextState(CartCtx, ["coupon"])

  if (state.coupons.length === 0) {
    WPApi.getAllCoupons().then((data) => {
      setState({
        ...state,
        coupons: data,
      })
    })
  }

  let couponAmount = 0
  if (state.coupons) {
    if (coupon) {
      state.coupons.map((el, k) => {
        if (el.code === coupon) {
          if(el.discount_type === "fixed_product"){
            couponAmount = el.amount
          } else {
            couponAmount = parseFloat(el.amount/100) * sum.val
          }
        }
      })
    }
  }

  return (
    <div>
      <div className={discount__wrapper}>
        <input
          type="text"
          placeholder="Mam kupon rabatowy"
          onChange={(ev) => {
            setCoupon(ev.target.value)
          }}
          value={coupon}
        />
      </div>
      <div className={cart__summary}>
        <h2>
          Razem:{" "}
          <span>
            {sum.val}
            <small>{unit}</small>
          </span>
        </h2>
        {couponAmount !== 0 ? (
          <h6>Wartość kuponu: {parseInt(couponAmount)} PLN</h6>
        ) : (
          <h6>&nbsp;</h6>
        )}
      </div>
    </div>
  )
}

export default Summary
