import React, { useRef, useContext } from "react"
import { Layout } from "@components/layout"
import { Stepper } from "@components/shared"
import { CartCtx } from "@components/contexted"

import { OrderAndDeliveryStep, CartSummaryStep, createOrder } from "./common"

const BeforeCustomerData = (props) => {
  const stepper = useRef({ setIndex: () => {} })
  const { state } = useContext(CartCtx)

  const { pageContext, cart } = props
  const { page } = pageContext
  const { imBusy } = cart
  return (
    <Layout {...page} imBusy={imBusy}>
      <div className="checkout">
        <Stepper ref={stepper} withScroll={true}>
          <CartSummaryStep
            {...props}
            {...{ stepper }}
            title={"1. Podsumowanie"}
          />
          <OrderAndDeliveryStep
            {...props}
            cart={state}
            createOrder={createOrder}
            {...{ stepper }}
            title={"2. Dane osobowe"}
          />
        </Stepper>
        {/* <AddToCart /> */}
      </div>
    </Layout>
  )
}

export default BeforeCustomerData
