import React, { useState } from "react"
import { WPApi } from "@api"
import {
  CartCtx,
  AppCtx,
  useActions,
  useContextState,
} from "@components/contexted"
import { AnimatedButton } from "@components/shared"
import {
  summary__bottom,
  discount__wrapper,
  sum__wrapper,
  price,
} from "../styles/checkout.module.scss"
import { parse } from "query-string"

const DiscountAndContinue = ({ nextStep, label = "Kontynuuj" }) => {
  const [state, setState] = useState({ coupons: [] })
  const { setCoupon } = useActions(CartCtx, ["setCoupon"])
  const { coupon, sum, shipping, items } = useContextState(CartCtx, [
    "coupon",
    "items",
    "sum",
    "shipping",
  ])
  const { imBusy } = useContextState(AppCtx, "imBusy")

  if (state.coupons.length === 0) {
    WPApi.getAllCoupons().then((data) => {
      setState({
        ...state,
        coupons: data,
      })
    })
  }

  let couponAmount = 0
  if (state.coupons) {
    if (coupon) {
      state.coupons.map((el, k) => {
        if (el.code === coupon) {
          if(el.discount_type === "fixed_product"){
            couponAmount = el.amount
          } else {
            couponAmount = parseFloat(el.amount/100) * sum.val
          }
        }
      })
    }
  }


  return (
    <div className={summary__bottom}>
      <div className={discount__wrapper}>
        <input
          type="text"
          placeholder="Mam kupon rabatowy"
          onChange={(ev) => {
            setCoupon(ev.target.value)
          }}
          value={coupon}
        />
      </div>
      <div className={sum__wrapper}>
        <h5>
          Razem:{" "}
          <span className={price}>
            {typeof shipping.total === "number"
              ? sum.val + shipping.total
              : sum.val}{" "}
            PLN
          </span>
        </h5>
        {couponAmount !== 0 ? (
          <h6>Wartość kuponu: {parseInt(couponAmount)} PLN</h6>
        ) : (
          <h6>&nbsp;</h6>
        )}
        <AnimatedButton onClick={nextStep} disabled={imBusy}>
          {imBusy ? "Procesowanie zamówienia" : label}
          <i className="icofont-double-right"></i>
        </AnimatedButton>
      </div>
    </div>
  )
}

export default DiscountAndContinue
