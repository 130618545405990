import React, { useContext } from "react"

import {
  CustomerCtx,
  useActions,
  CartCtx,
  AppCtx,
} from "@components/contexted"
import {
  CustomerForm,
  Delivery,
  DiscountAndContinue,
  ErrorMessage,
  createOrder,
} from ".."

const OrderAndDeliveryStep = () => {
  const customer = useContext(CustomerCtx)
  const cart = useContext(CartCtx)

  const { loading, loaded } = useActions(AppCtx, ["loading", "loaded"])
  const { setOrderFromApi, onOrderApiError } = useActions(CartCtx, [
    "setOrderFromApi",
    "onOrderApiError",
  ])

  return (
    <div title={"2. Wypełnij dane"}>
      <CustomerForm />
      <Delivery />
      <ErrorMessage cart={cart.state} />
      <DiscountAndContinue
        nextStep={() => {
          createOrder({
            cart: cart.state,
            customer: customer.state,
            initLoading: loading,
          })
            .then((data) => {
              loaded()
              setOrderFromApi(data)
            })
            .catch(({ data }) => {
              loaded()
              onOrderApiError(data)
            })
        }}
        label={"Potwierdź zamówienie"}
      />
      <div className="row justify-content-center">
        Dokonując zakupów oświadczasz, że zapoznałeś się i akceptujesz nasz
        &nbsp;
        <a href="/regulaminy"> regulamin</a>.
      </div>
    </div>
  )
}

export default OrderAndDeliveryStep
