import React, {useState} from "react"
import { Breadcrumbs, Reviews, Slider } from "@components/shared"
import { CartCtx, useActions, useContextState } from "@components/contexted"
import { WPApi } from "@api"

import {
  Description,
  Attributes,
  AddToCart,
  Tags,
  ProductAvailability,
} from "./common"

import {
  product__wrapper,
  product__inner,
  product__title,
  product__category,
  product__content,
  product__content__icon,
  product__longDescription,
  slider,
  discount__wrapper
} from "./styles/product.module.scss"

const SimpleProduct = (props) => {
  const [state, setState] = useState({ coupons: [] })
  const { setCoupon } = useActions(CartCtx, ["setCoupon"])
  const { coupon } = useContextState(CartCtx, ["coupon"])
  const { addToCart, product } = props

  if (state.coupons.length === 0) {
    WPApi.getAllCoupons().then((data) => {
      setState({
        ...state,
        coupons: data,
      })
    })
  }



  let couponAmount = 0
  if (state.coupons) {
    if (coupon) {
      state.coupons.map((el, k) => {
        if (el.code === coupon) {
          couponAmount = el.amount
        }
      })
    }
  }

  return (
    <div className={product__wrapper}>
      <div className={product__inner}>
        <h6 className={product__category}>
          {product.productCategories.nodes[0].name}
        </h6>
        <Breadcrumbs
          elements={[
            {
              label: product.productCategories.nodes[0].name,
              url: `/shop/categories/${product.productCategories.nodes[0].slug}/`,
            },
            {
              label: product.name,
              url: `/shop/products/${product.slug}/`,
            },
          ]}
        />

        <div className={product__content__icon}></div>
        <div className={product__content}>
          <div>
            
            <h2 className={product__title}>{product.name}</h2>

            {product.productTags && (
              <Tags productTags={product.productTags.nodes} />
            )}

            {product.attributes && (
              <Attributes attributes={product.attributes.nodes} />
            )}
            <Description description={product.shortDescription} />
            <ProductAvailability stockQuantity={product.stockQuantity} />
            <AddToCart
              {...product}
              onAddToCart={(item) => {
                // console.log('iii')
                addToCart({
                  ...item,
                  image: product.image,
                })
                console.log('a')
              }}
            />
          <div className={discount__wrapper}>
            <div>
            <input
          type="text"
          placeholder="Mam kupon rabatowy"
          onChange={(ev) => {
            setCoupon(ev.target.value)
          }}
          value={coupon}
        />
        {couponAmount !== 0 ? (
          <h6>Wartość kuponu: {parseInt(couponAmount)} PLN</h6>
        ) : (
          <h6>&nbsp;</h6>
        )}
            </div>
          
          </div>
          </div>

          {product.galleryImages && (
            <Slider className={slider} items={product.galleryImages.nodes} />
          )}
        </div>

        <div className={product__longDescription}>
          {/* <Stepper> */}
          <Description title={"Opis"} description={product.description} />
          <Reviews
            title={"Opinie"}
            productId={product.productId}
            contextReviews={product.reviews.nodes}
          />
          {/* </Stepper> */}
        </div>
      </div>
    </div>
  )
}

export default SimpleProduct
