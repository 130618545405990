import React from "react"
import { AnimatedButton } from "@components/shared"
import { CartCtx, useContextState } from "@components/contexted"

// tak wygląda consumer contextu, który używa stanu
const Toggler = ({ className, onClick }) => {
  const { items } = useContextState(CartCtx, "items")

  return (
    <AnimatedButton className={className} onClick={onClick}>
      <i class="fas fa-shopping-cart"></i> {items.length}
    </AnimatedButton>
  )
}

export default Toggler
