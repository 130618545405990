import React from "react"
import { WPApi } from "@api"
import {
  TextInput,
  AnimatedButton,
  AnimatedLink,
  AnimatedSection,
} from "@components/shared"
import halfmask from "./img/halfmask.svg"
import {
  wrapp,
  news__wrapper,
  news__wrapper__modal,
  header,
  header__title,
  header__subtitle,
  form,
  form__input,
  form__button,
  form__msg,
  link__more,
} from "./styles/newsletter.module.scss"

import is from "is_js"

class NewsletterForm extends React.Component {
  state = {
    email: "",
    msg: "",
    imBusy: false,
  }

  action = () => {
    if (is.email(this.state.email) === true) {
      localStorage.setItem("email", this.state.email)
      this.setState({
        imBusy: true,
      })
      WPApi.sendMail(this.state.email).then(({ message }) => {
        this.setState({
          email: "",
          msg: message,
          imBusy: false,
        })
      })
    }
  }

  render() {
    if (this.state.imBusy) {
      return (
        <div className={form}>
          <h6 className={form__msg}>Dodaję Cię do listy mailingowej</h6>
        </div>
      )
    }
    if (this.state.msg !== "") {
      return (
        <div className={form}>
          <h6 className={form__msg}>{this.state.msg}</h6>
        </div>
      )
    }

    const {
      title = "Dołącz do listy mailingowej Gladiatorów Javascriptu!",
      subtitle = "Będziesz otrzymywać linki do zajęć z Gladiatorami, przydatne do nauki artykuły oraz informacje odnośnie aktualizacji zbiorów",
      modal
    } = this.props

    return (
      <div className={modal ? (news__wrapper__modal):(news__wrapper)}>
        <div className={header}>
          <h3 className={header__title}>{title}</h3>
          <h5 className={header__subtitle}>{subtitle}</h5>
        </div>

        <AnimatedSection className={form}>
          <TextInput
            type="email"
            className={form__input}
            value={this.state.email}
            placeholder="Wprowadź adres email"
            onChange={(ev) => {
              const { value } = ev.target
              this.setState({
                email: value,
              })
            }}
          />
          <AnimatedButton className={form__button} onClick={this.action}>
            Zapisz się
            <i className="icofont-double-right"></i>
          </AnimatedButton>

          <AnimatedLink to={"/polityka-prywatnosci/"} className={link__more}>
            <small>
              * Zapisując się do newslettera zgadzasz się na przetwarzanie
              swoich informacji osobowych zgodnie z naszą Polityką Prywatności.
            </small>
          </AnimatedLink>
        </AnimatedSection>
      </div>
    )
  }
}

export const NewsletterSection = ({ title, subtitle, modal=false }) => {
  return (
    <div className={wrapp} id={"newsletter"}>
      <img src={halfmask} alt="" />
      <NewsletterForm title={title} subtitle={subtitle} modal={modal}/>
    </div>
  )
}

export default NewsletterSection
